.cutscene{
    opacity: 0;
    animation: fadeInAnimation 2s forwards;
}
.stars-wrapper{
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
}
.cutscene-star{
    width: 40px !important;
    height: 40px !important;
    color: gold;
    position: absolute;
    top: 0;
    stroke: rgba(255,255,255,0.8);
    animation: starShine 1s infinite;
}
.cutscene-star:nth-child(1){left: 15%;}
.cutscene-star:nth-child(2){left: 30%;}
.cutscene-star:nth-child(3){left: 45%;}
.cutscene-star:nth-child(4){left: 60%;}
.cutscene-star:nth-child(5){left: 75%;}

.cutscene-star.converge-animation{
    animation: convergeStars 5s forwards;
}
.cutscene-star.highlight-animation{
    animation: highlightStars 2s forwards;
}

@keyframes convergeStars {
    0%{}
    50%{color: rgb(255, 245, 187); transform: scale(1.2);}
    100%{ left: 45%; top: 100px}
}

@keyframes highlightStars {
    0%{ color: gold; transform: scale(1.2);}
    15%{color: rgb(255, 245, 187); transform: scale(1.5);}
    100%{ color: gold; transform: scale(1);}
}


.cutscene .navbar-avatar.avatar-pulse{
    animation: avatarPulse 2s forwards;
}

@keyframes avatarPulse {
    0%{transform: scale(1); opacity: 1;}
    50%{transform: scale(1.2); opacity: 0.9;}
    100%{transform: scale(1); opacity: 1;}
}