.MuiStepper-root {
  max-width: 100%;
  overflow: scroll;
}
.MuiStepper-root::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}
.MuiStepper-root {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.MuiStepper-root *{
  z-index: 100;
}
.MuiStepLabel-iconContainer {
  padding: 0 !important;
}

.MuiStepIcon-root{
  @apply !text-neutral-100 rounded-full
}
.MuiStepIcon-root.Mui-active {
  @apply !text-secondary
}


.MuiStepIcon-root .MuiStepIcon-text{
  @apply !fill-neutral-400
}
.MuiStepIcon-root.Mui-active .MuiStepIcon-text{
  @apply !fill-white
}


.MuiStepButton-root {
  padding-bottom: 10px !important;
  padding-top: 6px !important;
}
  .MuiButtonBase-root{
    @apply py-0
  }


.task-completed-msg{
  animation: taskCompleted 2s forwards;
  color: #fff;
}

@keyframes taskCompleted {
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}

.add-point-animation{
  animation: addPointAnimation 1.5s forwards;
}

.add-point-and-shine-animation{
  animation: addPointAnimation 1.5s forwards, animateStarColor 3s infinite;
}

@keyframes addPointAnimation {
  0%{ padding: auto; opacity: 1;}
  50%{ padding: 20px; opacity: 0.7;}
  100%{ padding: auto; opacity: 1;}
}

.font-size-animation{
  animation: fontSizeAnimation 2s forwards;
}

@keyframes fontSizeAnimation {
  0%{ font-size: auto;}
  50%{  font-size: 22px;}
  100%{  font-size: auto;}
}

.opacity-animation{
  animation: opacityAnimation 2s forwards;
}

@keyframes opacityAnimation {
  0%{ opacity: 1;}
  50%{  opacity: 0.6;}
  100%{  opacity: 1;}
}

.add-full-star-animation{
  animation: addFullStar 2.5s forwards, animateStarColor 0.5s infinite;
}

@keyframes addFullStar {
  0%{ overflow: visible; bottom:0}
  50%{width: 10px; height: 10px;}
  100%{  width: 0px; height: 0px; bottom: 100vh}
}


@keyframes animateStarColor {
  0%{color: gold;}
  50%{color: white}
  100%{color: gold;}
}