.points-section {
    font-size: 18;
}
.points-section .heading{
    font-size: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.points-section .points-by-type .star-icon{
    color: yellow;
    position: relative;
    bottom: 2px;
}


/* SubTabs */

.points-sub-tabs{
    @apply bg-primary fixed bottom-[70px] left-0 w-full z-[200] h-[70px] grid items-center justify-center py-3 
}
.points-sub-tabs .tabs-wrapper{
    @apply w-[60vw]  text-white rounded-lg grid grid-cols-2 justify-center
}
.points-sub-tabs .tabs-wrapper .tab{
    @apply grid justify-center items-center py-1 duration-500 text-white dark:text-white cursor-pointer bg-white/20
}
.points-sub-tabs .tabs-wrapper .tab.active{
    @apply  bg-white text-primary 
}
.points-sub-tabs .tabs-wrapper .tab:nth-child(1){
    @apply border-r-[1px] border-white rounded-l-lg
}
.points-sub-tabs .tabs-wrapper .tab:nth-child(2){
    @apply border-l-[1px] border-white rounded-r-lg
}

/* RANKINGS */
.ranking-table{
    @apply pl-2 pt-5
}
.ranking-table .ranking-table-row{
    overflow: visible;    
}
.ranking-table .ranking-table-row .MuiAvatar-root{
    z-index: 100 !important;
    position: relative;
    overflow: visible;
    border-radius: 100% !important;
}
.ranking-table .ranking-table-row .MuiAvatar-root img{
    border-radius: 100% !important;
}
.ranking-table .ranking-table-row .MuiAvatar-root::before{
    content: "";
    position: absolute;
    width: 120%;
    height: 120%;
    border-radius: 100%;
    z-index: -1 !important;
}
.ranking-table .ranking-table-row:nth-child(1) .MuiAvatar-root::before{
    border: 3px solid #fff;
    background: yellow;
}
.week-ranking .ranking-table .ranking-table-row .medal-wrapper{
    background: url("/public/img/medals/gold-medal.png");
    background-size: cover !important;
    width: 20px;
    height: 28px;
    display: inline-block;
    position: relative;
    margin-left: 5px;
    top: 10px;
    right: 2px;
}

.ranking-table .ranking-table-row:nth-child(2) .MuiAvatar-root::before{
    border: 3px solid #fff;
    background: silver;
}
.week-ranking .ranking-table .ranking-table-row:nth-child(2) .medal-wrapper{
    background-image: url("/public/img/medals/silver-medal.png");
}

.ranking-table .ranking-table-row:nth-child(3) .MuiAvatar-root::before{
    border: 3px solid #fff;
    background: #CD7F32;
}
.week-ranking .ranking-table .ranking-table-row:nth-child(3) .medal-wrapper{
    content: "";
    background-image: url("/public/img/medals/bronze-medal.png");
}



/*Global table */

.ranking-table-wrapper.global{
    max-height: 307px !important;
    padding: 0 7px 7px 7px !important;
}
.ranking-table.global{
    padding: 0 !important;
}

.ranking-table.global .ranking-table-row .MuiAvatar-root::before{
    border: none !important;
    background: none !important;
}
.ranking-table.global .ranking-table-row .trophy-wrapper{
    border: none !important;
    background-size: cover !important;
    width: 23px;
    height: 31px;
    position: relative;
    display: inline-block;
    top: 5px;
    z-index: 250;
    margin-left: 5px;
}


.ranking-table.global .ranking-table-row{
    @apply border-[1px] border-neutral-100/40 
}
.ranking-table.global .ranking-table-row:nth-last-child(1){
    @apply rounded-b-md
}

.ranking-table.global .ranking-table-row:nth-child(1){
    background: yellow;
    border-radius: 5px 5px 0 0;
}
.ranking-table.global .ranking-table-row:nth-child(1) .trophy-wrapper{
    background-image: url("/public/img/medals/gold-trophy.png");
}

.ranking-table.global .ranking-table-row:nth-child(2){
    background: silver;
}
.ranking-table.global .ranking-table-row:nth-child(2) .trophy-wrapper{
    background-image: url("/public/img/medals/silver-trophy.png");
}


.ranking-table.global .ranking-table-row:nth-child(3){
    background: #CD7F32;
}
.ranking-table.global .ranking-table-row:nth-child(3) .trophy-wrapper{
    background-image: url("/public/img/medals/bronze-trophy.png");
}


.ranking-table.global .ranking-table-row{
    height: 60px;
}


/* Subtract navbar height + student avatar and name banner + bottom tab bars  */
/*  72 + 53 + 70 + 70 = 265px*/
.user-scores-scrollable-wrapper{
    max-height: calc(100vh - 265px); 
}
